<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="600px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="150px"
      >
        <el-form-item label="员工" prop="employeeId">
          <el-select
            v-model="airForm.employeeId"
            placeholder="请选择员工"
            filterable
            remote
            :remote-method="getEmployee"
            :disabled="handleType !== 'add' && handleType !== 'update'"
          >
            <el-option
              v-for="item in EmployeeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="培训日期" prop="trainingDate">
          <el-date-picker
            v-model="airForm.trainingDate"
            type="datetime"
            placeholder="选择培训日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          >
          </el-date-picker>
        </el-form-item>
         <el-form-item label="培训类型" prop="type">
          <el-select
            v-model="airForm.type"
            placeholder="请选择培训类型"
            :clearable="handleType === 'add' || handleType === 'update'"
            :disabled="handleType !== 'add' && handleType !== 'update'"
          >
            <el-option
              label="内培"
              :value="1"
            />
            <el-option
              label="外培"
              :value="2"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="培训课程" prop="course">
          <el-input
            v-model="airForm.course"
            placeholder="请输入培训课程"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="培训师" prop="trainer">
          <el-input
            v-model="airForm.trainer"
            placeholder="请输入培训师"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="课时" prop="classHour">
          <el-input
            v-model="airForm.classHour"
            placeholder="请输入课时"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="考核结果" prop="assessmentResult">
          <el-input
            v-model="airForm.assessmentResult"
            placeholder="请输入考核结果"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>

        <el-form-item label="备注" prop="remarks">
          <el-input
            v-model="airForm.remarks"
            placeholder="请输入备注"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="培训课程">
        <el-input
          v-model="searchForm.course"
          placeholder="请输入培训课程"
          clearable
        />
      </el-form-item>
      <el-form-item label="姓名">
        <el-input
          v-model="searchForm.name"
          placeholder="请输入姓名"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="waterList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="员工姓名" />
      <el-table-column prop="course" label="培训课程" />
      <el-table-column prop="trainingDate" label="培训日期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="waterList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getTrainList,
  deleteTrainList,
  addTrainList,
  getTrainDetail,
  updateTrainDetail,
  getEmployeeList,
} from "@/api/humanManagement/humanManagement.js";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      airFormTitle: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      waterList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules: {
        disinfectionObject: [
          {
            required: true,
            message: "时间名称不能为空",
            trigger: ["change"],
          },
        ],
      },
      productFormRules2: {
        employeeId: [
          {
            required: true,
            message: "员工不能为空",
            trigger: ["change", "blur"],
          },
        ],
        trainingDate: [
          {
            required: true,
            message: "培训日期不能为空",
            trigger: ["change", "blur"],
          },
        ],
        type: [
          {
            required: true,
            message: "培训类型不能为空",
            trigger: ["change", "blur"],
          },
        ],
        course: [
          {
            required: true,
            message: "培训课程不能为空",
            trigger: ["change", "blur"],
          },
        ],
        trainer: [
          {
            required: true,
            message: "培训师不能为空",
            trigger: ["change", "blur"],
          },
        ],
        classHour: [
          {
            required: true,
            message: "课时不能为空",
            trigger: ["change", "blur"],
          },
        ],
        assessmentResult: [
          {
            required: true,
            message: "考核结果不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
      EmployeeList: [],
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let waterList = await getTrainList(params);
    this.waterList = waterList;
    
  },
  methods: {
   async getEmployee(name){
      if(name){
        let params2 = {
          pageNum: 1,
          pageSize: 100,
          name
        };
        let EmployeeList = await getEmployeeList(params2);
        console.log(EmployeeList,'EmployeeList')
        this.EmployeeList = EmployeeList.list;
      }else{
        this.EmployeeList = [];
      }
    
    },
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addTrainList(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            await updateTrainDetail(params);
          }
          this.waterList = await getTrainList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    productFormSubmit2() {
      this.$refs.productFormRef.validate(async (valid) => {
        this.airDetailForm.airId = this.airForm.id;
        if (valid) {
          if (this.handleDetailType === "add") {
            let data = await addTrainList(this.airDetailForm);
            let list = this.airForm.airDetailList;
            list.push(data);
            this.airForm.airDetailList = list;
          } else if (this.handleDetailType === "update") {
            let list = this.airForm.airDetailList;
            console.log(this.airDetailForm, "this.airDetailForm");
            delete this.airDetailForm.index;
            delete this.airDetailForm.inputType;
            list.splice(this.airDetailForm.index, 1, this.airDetailForm);
            this.airForm.airDetailList = list;
          }
          this.innerVisible = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增员工培训记录";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteTrainList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.waterList = await getTrainList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "员工培训记录";
      this.handleType = "update";
      getTrainDetail(row.id).then((res) => {
        this.airForm = { ...res };
        // this.runningTime = [res.startTime || "", res.endTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "员工培训记录";
      this.handleType = "detail";
      getTrainDetail(row.id).then((res) => {
        this.airForm = { ...res };
        console.log({ ...res });
        // this.runningTime = [res.startupTime || "", res.offTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getTrainList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.waterList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getTrainList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getTrainList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
  },

  watch: {
    runningTime(val, oldVal) {
      console.log(val, "val");
      this.airForm.entryExitDate = val.join("-");
    },
    airDialogVisible(val) {
      if (!val) {
        this.airForm = {};
        this.runningTime = [];
      }
    },
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
